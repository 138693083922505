.header-section {
  display: flex;
  justify-content: space-between;
  padding: 1rem 4rem;
  position: sticky;
}

.header-left {
  display: flex;
  flex: 0.5;
  justify-content: flex-start; 
  align-items: last baseline;
}

.header-right {
  display: flex;
  flex: 0.5;
  justify-content: flex-end; 
  align-items: last baseline;
}

.vertical-div {
  width: 1px;
  height: 100%;
  background-color: black;
  margin: 0 1.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header-name {
  font-weight: bold;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 100;
  margin: 0 1.5rem;
}

.header-options {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: lighter;
}

.home-pic {
  width: 65%;
  height: 65%;
  border-radius: 50%;
  border: 1px solid black;
  margin: 0 2rem;
}

.home-name {
  font-weight: bold;
  font-size: 46px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.home-section-top {
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}

.home-subtitle {
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 1.5px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 18px;
}

.home-section {
  height: 90vh;
}

.home-about {
  font-weight: 200;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 1px;
  margin: 0.75rem 0;
  font-size: 15px;
  line-height: 1.6;
}

.home-section-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.break-line {
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 2rem;
  margin-right: 4rem;
  border: 1px solid lightgray;
}

.home-category {
  border-radius: 50%;
  border: 1px solid black;
  height: 100px;
  width: 100px;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover-effect {
  position: relative;
  overflow: hidden;
}

.hover-effect::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.3s ease;
  pointer-events: none; /* Ensure the pseudo-element does not interfere with other elements */
}

.hover-effect:hover::after {
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for desired transparency */
}

.footer-section {
  display: flex;
  justify-content: center;
  padding: 1rem 4rem;
  height: 20vh;
}

.footer-topic {
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.footer-desc {
  margin-top: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: lighter;
  font-size: 14px;
}

.resume-section {
  padding: 1rem 4rem;
  min-height: 85vh;
}

.section-title {
  font-size: 40px;
  margin: 1rem 0;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.resume-download {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
  /* border: 1px solid lightgray; */
  /* background-color: aliceblue; */
}

.project-section {
  padding: 1rem 4rem;
  min-height: 85vh;
}

.project-title {
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 24px;
  line-height: 1.4;
  width: 400px;
}

.project-desc {
  font-weight: lighter;
  margin-top: 1rem;
  line-height: 1.6;
  width: 400px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.project-card {
  /* width: 400px; */
  padding: 2rem 4rem;
  /* margin: 0 auto; */
}

.project-card-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.projects-list {
  padding: 1rem 4rem;
}

.button{
  padding: 0.5rem 1rem;
  background-color: aliceblue;
  border: none;
  letter-spacing: 1.2px;
}

.button:hover {
  cursor: pointer;
  background-color: lightblue;
  color: blue;
}

.cert-section {
  padding: 1rem 4rem;
  height: 85vh;
  margin: 0 auto;
}

.cert-title {
  font-weight: lighter;
  font-size: 18px;
  margin-right: 2rem;
  /* margin: 1rem 0; */
}

.cert-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
}

.resume-preview {
  height: 750px;
  width: 600px;
  margin: 2rem auto;
}

.cert-view-btn {
  width: 200px;
}

@media only screen and (max-width: 600px) {

  .header-section {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    position: sticky;
  }

  .header-name {
    font-weight: bold;
    font-size: 12px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .header-title {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 100;
    margin: 0 0.5rem;
    font-size: 12px;
    display: none;
  }

  .header-options {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: lighter;
    font-size: 12px;
  }

  /* .header-left {
    display: flex;
    flex: 0.3;
    justify-content: flex-start; 
    align-items: last baseline;
  } */

  /* .header-right {
    display: flex;
    flex: 0.7;
    justify-content: flex-end; 
    align-items: last baseline;
  } */

  .vertical-div {
    width: 1px;
    height: 100%;
    background-color: black;
    margin: 0 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .break-line {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 2rem;
    border: 1px solid lightgray;
  }

  .footer-section {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    height: 20vh;
  }

  .home-section-top {
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }

  .home-section {
    margin-top: 6rem;
    padding: 1rem 1.5rem;
  }

  .home-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-name{
    text-align: center;
    font-size: 32px;
    margin-top: 2rem;
  }

  .home-subtitle {
    font-size: 14px;
  }

  .home-about {
    font-size: 12px;
  }

  .home-section-bottom {
    margin-top: 4rem;
  }

  .home-category {
    font-size: 12px;
  }

  .footer-desc {
    font-size: 12px;
  }

  .resume-section {
    padding: 1rem 1rem;
    min-height: 85vh;
  }
  
  .section-title{
    font-size: 24px;
  }

  .resume-preview {
    height: 750px;
    width: 400px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .project-section {
    padding: 1rem 1rem;
    min-height: 85vh;
  }

  .projects-list {
    padding: 1rem 1rem;
  }

  .project-card {
    /* width: 400px; */
    padding: 2rem 1rem;
    /* margin: 0 auto; */
  }

  .project-desc {
    font-weight: lighter;
    margin-top: 1rem;
    line-height: 1.6;
    width: 100%;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .project-title {
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    line-height: 1.4;
    width: 100%;
  }

  .project-card-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .projects-download-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
  }

  .cert-section {
    padding: 1rem 1rem;
    height: 85vh;
    margin: 0 auto;
  }

  .cert-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1rem;
  }

  .cert-title {
    font-weight: lighter;
    font-size: 14px;
    flex: 0.5;
    /* margin: 1rem 0; */
  }

  .cert-view-btn {
    width: 100px;
    display: flex;
    justify-content: flex-end;
  }
}
